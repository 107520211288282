import React, { FunctionComponent, PropsWithChildren } from 'react'
import classNames from 'classnames/bind'

import styles from './Content.module.scss'
const cx = classNames.bind(styles)

export const Content: FunctionComponent = ({
  children
}: PropsWithChildren<{}>) => {
  return (
    <>
      <div className={styles.banner}></div>
      <div className={cx('container', 'layout')}>{children}</div>
    </>
  )
}

export const Title: FunctionComponent = ({
  children
}: PropsWithChildren<{}>) => <h1 className={styles.title}>{children}</h1>
